import 'iframe-resizer/js/iframeResizer.contentWindow';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import './common/palette.scss';
import { main } from './configuration';
import { config } from './configuration/config';
import './configuration/setup/datadog';
import { handleLoginRedirect } from './configuration/setup/redirect';
import { store } from './configuration/setup/store';
import { router } from './routes/Router';

const renderApplication = () => {
    createRoot(document.getElementById('root') as HTMLElement).render(
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    );
};

if (config.enableMockServer) {
    import('../test/mocks/lib/serviceMock').then(({ worker }) => {
        worker.start({
            onUnhandledRequest: 'bypass',
        });
        main(renderApplication);
    });
} else {
    main(renderApplication);
}

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
}
