import { createAction, createSlice } from '@reduxjs/toolkit';
import { STORE_BASE_PATH } from '../../common/constants';
import { CenterMapType } from './actions/centerMap';
import { RenderShapeType } from './actions/renderShapes';
import { MapContext, MapContextType } from './events/setMapContext';
import { SelectGeofencesType } from './events/selectGeofences';
import { SelectPoisType } from './events/selectPois';
import { BoundingBoxType } from './actions/changeBoundingBox';

export const widgetActions = {
    widgetLoaded: createAction('EVENT_WIDGET_LOADED'),
    setWidgetId: createAction('EVENT_SET_WIDGET_ID'),
    centerMap: createAction<CenterMapType>('EVENT_CENTER_MAP'),
    setVisibleWidgets: createAction('EVENT_SET_VISIBLE_WIDGETS'),
    centerActiveAsset: createAction('EVENT_CENTER_ACTIVE_ASSET'),
    setBoundingBox: createAction<BoundingBoxType>('EVENT_SET_BOUNDING_BOX'),
    renderShapes: createAction<RenderShapeType>('EVENT_RENDER_SHAPE'),
};

export interface WidgetState {
    selectedAssetId: string | null;
    widgetId?: string;
    isVisible: boolean;
    mapContext: MapContextType;
    selectedPois: SelectPoisType;
    selectedGeofences: SelectGeofencesType;
}

const initialState: WidgetState = {
    selectedAssetId: null,
    isVisible: false,
    mapContext: MapContext.MAP_CONTEXT_DEFAULT,
    selectedPois: [],
    selectedGeofences: [],
};

export const widgetSlice = createSlice({
    name: `${STORE_BASE_PATH}widget`,
    initialState,
    reducers: {
        updateSelectedAssetId: (state, action) => {
            state.selectedAssetId = action.payload;
        },
        updateWidgetId: (state, action) => {
            state.widgetId = action.payload;
        },
        updateWidgetVisibility: (state, action) => {
            state.isVisible = action.payload;
        },
        updateMapContext: (state, action) => {
            state.mapContext = action.payload;
        },
        updateSelectedPois: (state, action) => {
            state.selectedPois = action.payload;
        },
        updateSelectedGeofences: (state, action) => {
            state.selectedGeofences = action.payload;
        },
    },
});

export const {
    updateSelectedAssetId,
    updateWidgetId,
    updateWidgetVisibility,
    updateMapContext,
    updateSelectedPois,
    updateSelectedGeofences,
} = widgetSlice.actions;

export default widgetSlice.reducer;
