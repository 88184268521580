import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { cloneDeepWith } from 'lodash';
import { config } from '../config';

const isLocalhost = () => window.location.hostname === 'localhost';

const tokenStringReplacer = (val: string) => {
    if (val && typeof val.replace === 'function') {
        val = val.replace(/access_token=[^&]*/, 'access_token=REDACTED');
        val = val.replace(/id_token=[^&]*/, 'id_token=REDACTED');
        return val;
    }
};

const env = config.id === 'env.production' ? 'prod' : 'staging';
const serviceName = `range-widget${env === 'staging' ? '-dev' : '-prod'}`;

const setupErrorLogger = () => {
    const clientToken = config.datadog.datadog_logs_client_token;
    datadogLogs.init({
        clientToken,
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sampleRate: 100,
        service: serviceName,
        env,
        beforeSend: (obj) => cloneDeepWith(obj, tokenStringReplacer),
    });

    datadogLogs.setGlobalContextProperty('account_id', config.aws_client_id);
    datadogLogs.setGlobalContextProperty('team', 'MAN Smart Route Planning');
    datadogLogs.setGlobalContextProperty('team_identifier', 'man-team-smart-route-planning');
};

const setupRealUserMonitoring = () => {
    const applicationId = config.datadog.datadog_rum_app_id;
    const clientToken = config.datadog.datadof_rum_client_token;
    const version = config.build_version;
    datadogRum.init({
        applicationId,
        clientToken,
        site: 'datadoghq.eu',
        service: serviceName,
        version,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        env,
        defaultPrivacyLevel: 'mask',
    });

    datadogRum.startSessionReplayRecording();
};

if (!isLocalhost()) {
    setupErrorLogger();
    setupRealUserMonitoring();
}
